<template>
  <div class="leftBotContainer">
    <div class="btnBox flex">
      <div class="leftBtn flex-1 flex">
        <div
          class="btnItem"
          :class="{ redBg: btnActive == '1' }"
          @click="handClick('1')"
        >
          {{ $t("market.buyMore") }}
        </div>
        <div
          class="btnItem"
          :class="{ greenBg: btnActive == '2' }"
          @click="handClick('2')"
        >
          {{ $t("market.buyEmpty") }}
        </div>
      </div>
    </div>
    <div class="numBox">
      <el-select v-model="typeVal" :placeholder="$t('tips.pleaseSelect')" style="width: 100%">
        <el-option
          v-for="item in buyTypeList"
          :key="item.value"
          size="mini"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="numBox">
      <el-input
        v-model="num"
        style="width: 100%"
        size="small"
        readonly
        :placeholder="$t('market.commission')"
      ></el-input>
    </div>
    <p class="tipP">{{ $t("market.area") }}：0 - 0.42329</p>
    <div class="numBox">
      <el-input-number
        v-model="numArea"
        style="width: 100%"
        size="small"
        controls-position="right"
        @change="numberChange"
        :placeholder="$t('market.commission')"
      ></el-input-number>
    </div>
    <div class="numTips">
      <p>
        {{ $t("market.canUse") }}USDT{{ $t("market.balance") }}：{{
          balancePrice
        }}
      </p>
      <p>= 0.00000 USDT</p>
      <p>
        {{ $t("market.serviceCharge") }}{{ balancePrice }} ADAUSDT （{{
          serviceCharge
        }}%）
      </p>
    </div>
    <div
      class="footerBtn flex flex-center"
      @click="submit"
      :class="{ redBg: btnActive == '1', greenBg: btnActive == '2' }"
    >
      {{
        btnActive == "1" ? $t("market.buyMore") : $t("market.buyEmpty")
      }}ADAUSDT
    </div>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance } from "vue";
import { useOption } from "@/hooks/useOption";
import { Message } from "element-ui";
const { buyTypeList } = useOption();
const vm = getCurrentInstance();
const btnActive = ref("1");
const handClick = (val) => {
  btnActive.value = val;
};
//   委托方式
const typeVal = ref("1");
const num = ref(null);
const numArea = ref(null);
// 数量改变
const numberChange = () => {};
// 余额-手续费
const balancePrice = ref("534534");
const serviceCharge = ref("0.00");
const submit = () => {
  if (!numArea.value) {
    Message.error(vm.proxy.$t("market.commissionPlac"));
    return false;
  }
};
</script>

<style lang="scss" scoped>
.leftBotContainer {
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  .btnBox {
    font-weight: bold;
    .leftBtn {
      .btnItem {
        width: calc(50% - 5px);
        border: none;
        font-size: 14px;
        background-color: $theme-color;
        border: 1px solid $market-list-hover;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
          background-color: #cd4e65;
          color: #fff;
        }
        &:last-child {
          margin-left: 10px;
          &:hover {
            background-color: #1cad90;
          }
        }
      }
    }
  }
  .tipP {
    margin: 5px 0;
    font-size: 13px;
  }
  .numBox {
    width: 100%;
    margin-top: 10px;
  }
  .numTips {
    margin-top: 10px;
    font-size: 13px;
    p {
      margin: 5px 0;
    }
  }
  .footerBtn {
    width: 100%;
    cursor: pointer;
    height: 38px;
    margin-top: 15px;
    font-weight: bold;
    border-radius: 5px;
  }
}
</style>
