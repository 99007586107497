<template>
  <div class="rightTopContainer">
    <div class="headerContent">
      <span>{{ $t("market.price") }}(USDT)</span>
      <span>{{ $t("market.volume") }}</span>
    </div>
    <div class="barContent">
      <div
        class="flex redBar"
        v-for="(item, index) in objList.upList"
        :key="index"
      >
        <span>{{ item.price }}</span>
        <div class="flex-1 barBox">
          {{ item.num }}
          <div class="bar" style="width: 20%"></div>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="barContent">
      <div
        class="flex greenBar"
        v-for="(item, index) in objList.downList"
        :key="index"
      >
        <span>{{ item.price }}</span>
        <div class="flex-1 barBox">
          {{ item.num }}
          <div class="bar" style="width: 20%"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance } from "vue";
const vm = getCurrentInstance();
const props = defineProps({
  objList: {
    type: Object,
    default: () => [],
  },
});
</script>

<style lang="scss" scoped>
.rightTopContainer {
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  height: 315px;
  .headerContent {
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .barContent {
    .barBox {
      position: relative;
    }
    .bar {
      height: 18px;
      position: absolute;
      opacity: 0.5;
    }
    .redBar {
      .bar {
        background-color: #cd4e65;
      }
      span {
        color: #cd4e65;
        font-weight: bold;
      }
    }
    .greenBar {
      .bar {
        background-color: #1cad90;
      }
      span {
        color: #1cad90;
        font-weight: bold;
      }
    }
    .barBox {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
    }
  }
}
</style>
