<template>
  <div class="marketDetail flex">
    <div class="leftInfo flex-1 flex-col width10">
      <div class="leftTop shadow-box bg-box">
        <leftTop :list="leftTopList"></leftTop>
      </div>
      <div class="leftBot shadow-box bg-box" style="margin-top: 10px">
        <leftBot></leftBot>
      </div>
    </div>
    <div class="midChar flex-2 width10">
      <div class="midBot flex-1 shadow-box bg-box">
        <lineChar :charData="charData" @changeHeader="changeHeader"></lineChar>
      </div>
    </div>
    <div class="rightList flex-1 shadow-box width10">
      <div class="leftTop shadow-box bg-box">
        <rightTop :objList="rightTopList"></rightTop>
      </div>
      <div class="leftBot shadow-box bg-box" style="margin-top: 10px">
        <rightBot></rightBot>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import leftTop from "@/components/market/marketCurrency/leftTop.vue";
import leftBot from "@/components/market/marketCurrency/leftBot.vue";
import rightTop from "@/components/market/marketCurrency/rightTop.vue";
import rightBot from "@/components/market/marketCurrency/rightBot.vue";
import lineChar from "@/components/market/marketDetail/lineChar.vue";
const leftTopList = ref([
  {
    name: "黄金现货",
    num: "12312",
    rate: "1.5",
  },
  {
    name: "黄金现货",
    num: "12312",
    rate: "-1.5",
  },
]);
const rightTopList = ref({
  upList: [
    {
      price: "0.432",
      num: "435435345",
    },
    {
      price: "0.432",
      num: "4335345",
    },
    {
      price: "0.432",
      num: "43543345",
    },
    {
      price: "0.432",
      num: "43543345",
    },
    {
      price: "0.432",
      num: "43543345",
    },
  ],
  downList: [
    {
      price: "0.432",
      num: "435435345",
    },
    {
      price: "0.432",
      num: "4335345",
    },
    {
      price: "0.432",
      num: "43543345",
    },
    {
      price: "0.432",
      num: "43543345",
    },
    {
      price: "0.432",
      num: "43543345",
    },
  ],
});
// 图表数据
const charData = ref([
  {
    close: 4976.16,
    high: 4977.99,
    low: 4970.12,
    open: 4972.89,
    timestamp: 1587660000000,
    volume: 204,
  },
  {
    close: 4977.33,
    high: 4979.94,
    low: 4971.34,
    open: 4973.2,
    timestamp: 1587660060000,
    volume: 194,
  },
  {
    close: 4977.93,
    high: 4977.93,
    low: 4974.2,
    open: 4976.53,
    timestamp: 1587660120000,
    volume: 197,
  },
  {
    close: 4966.77,
    high: 4968.53,
    low: 4962.2,
    open: 4963.88,
    timestamp: 1587660180000,
    volume: 28,
  },
  {
    close: 4961.56,
    high: 4972.61,
    low: 4961.28,
    open: 4961.28,
    timestamp: 1587660240000,
    volume: 184,
  },
  {
    close: 4964.19,
    high: 4964.74,
    low: 4961.42,
    open: 4961.64,
    timestamp: 1587660300000,
    volume: 191,
  },
  {
    close: 4968.93,
    high: 4972.7,
    low: 4964.55,
    open: 4966.96,
    timestamp: 1587660360000,
    volume: 105,
  },
  {
    close: 4979.31,
    high: 4979.61,
    low: 4973.99,
    open: 4977.06,
    timestamp: 1587660420000,
    volume: 35,
  },
  {
    close: 4977.02,
    high: 4981.66,
    low: 4975.14,
    open: 4981.66,
    timestamp: 1587660480000,
    volume: 135,
  },
  {
    close: 4985.09,
    high: 4988.62,
    low: 4980.3,
    open: 4986.72,
    timestamp: 1587660540000,
    volume: 76,
  },
]);
// 图表头部切换
const changeHeader = (e) => {
  console.log(e);
};
// 右边列表
const rightListData = ref([
  {
    name: "黄金现货",
    num: "12312",
    direction: "买多",
    directionStatus: "1",
    time: "15:26",
  },
  {
    name: "黄金现货",
    num: "12312",
    direction: "买空",
    directionStatus: "0",
    time: "15:26",
  },
]);
const mideTopData = ref({
  name: "ETH",
  nowPrice: "432",
  rate1: "1.5",
  rate2: "2.5",
  beginPrice: "12312",
  highPrice: "12312",
  lowPrice: "12312",
  num: "12312", // 成交额
});
</script>
<style lang="scss" scoped>
.marketDetail {
  margin: 5px 20px;
  .bg-box {
    border-radius: 5px;
  }
  .width10 {
    width: 10px;
  }
  .leftInfo {
    padding: 5px;
    box-sizing: border-box;
  }
  .leftTop {
    padding: 5px;
    height: 315px;
    box-sizing: border-box;
  }
  .midChar {
    margin: 5px 20px;
  }
  .rightList {
    margin-top: 5px;
    height: fit-content;
    box-sizing: border-box;
  }
}
</style>
