<template>
  <div class="leftTopContainer flex-col">
    <div class="titleBox">
      {{ $t("market.coinCurrency") }}
    </div>
    <div class="listBox">
      <div v-for="(item, index) in list" :key="index" class="cur-p itemList">
        <div class="flex jus-bet">
          <span>{{ item.name }}</span>
          <span :class="item.rate > 0 ? 'red' : 'green'">{{ item.num }}</span>
        </div>
      </div>
      <el-empty v-if="list.length == 0" :image-size="100"></el-empty>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
const activeName = ref("1");
const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
});
const handleClick = (e) => {
  console.log(e);
};
</script>

<style lang="scss" scoped>
.leftTopContainer {
  padding: 4px;
  box-sizing: border-box;
  height: 100%;
  .titleBox {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin: 5px 0;
  }
  .tabsBox {
    margin-bottom: 5px;
    :deep(.el-tabs__header) {
      margin: 0;
    }
    :deep(.el-tabs__item) {
      font-size: 14px;
      margin-right: 5px;
      border-radius: 3px;
    }
  }
  .listBox {
    flex: 1;
    height: 1px;
    overflow-y: auto;
    .itemList {
      font-size: 14px;
      border: 1px solid #e2e2e2;
      margin-top: 5px;
      border-radius: 5px;
      padding: 2px 15px;
      box-sizing: border-box;
      &:hover {
        background: #5670f6;
        border: 1px solid $market-list-hover;
        span {
            color: #fff !important;
        }
      }
    }
  }
}
</style>
