<template>
  <div class="container">
    <div class="botHeader flex jus-bet">
      <div class="headerLet flex">
        <span @click="nowActive = '1'" :class="{ active: nowActive === '1' }">{{
          $t("market.currentCommission")
        }}</span>
        <span @click="nowActive = '2'" :class="{ active: nowActive === '2' }">{{
          $t("market.introduction")
        }}</span>
      </div>
      <span class="active" @click="jumpPage('/transactionRecords')">{{ $t("market.wholeAll") }}</span>
    </div>
    <div class="contentBox">
      <template v-if="nowActive == '1'">
        <el-empty v-if="list.length == 0" :image-size="100"></el-empty>
      </template>
      <template v-else>
        <span
          >卡尔达诺是一个分散且公开的区块链，也是ㄧ个加密货币项目，而且是完全开源的。卡尔达诺正在开发一个智能合约平台，旨在提供比以前开发的任何协议更先进的功能。它是第一个从科学哲学和以研究为首驱使导向，进而演变而来的区块链平台。开发团队由全球专业工程师和研究人员组成。
          发行时间 2017-10-02 发行总量 45,000,000,000
          数据来源第三方CoinGecko官网 流通总量 35,045,020,830
          数据来源第三方CoinGecko官网 总市值 13,700,880,000 USDT 流通市值
          10,669,947,221 USDT</span
        >
      </template>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "@/utils/vueApi";
import { ref } from "vue";
const router = useRouter();
const nowActive = ref("1");
const list = ref([]);
const jumpPage = (url) => {
  router.push(url);
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  box-sizing: border-box;
  height: 315px;
  display: flex;
  flex-direction: column;
  .botHeader {
    .headerLet {
      span {
        margin-right: 20px;
      }
    }
    span {
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #5670f6;
      }
    }
  }
  .contentBox {
    overflow-y: auto;
    flex: 1;
    margin-top: 20px;
    font-size: 14px;
  }
}
</style>
